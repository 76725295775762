@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.content {
  @include font-style-default($font-weight-bold);

  width: 98px;
  height: 66px;
  margin: 0;
  padding: 6px 10px;
  border-top-left-radius: 8px;
}

.light {
  background: var(--static-gray-110);

  & .content {
    background-color: var(--static-white);
    color: var(--static-black);
  }
}

.dark {
  background-color: var(--static-black);

  & .content {
    background: var(--static-gray-10);
    color: var(--static-white);
  }
}

.card {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  width: 124px;
  height: 92px;
  border-radius: 8px;

  overflow: hidden;
}
